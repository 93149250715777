/* BackgroundVideo.css */

.background-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(7px); /* Adjust the blur intensity as needed */
    z-index: -1; /* Keeps the video behind other content */
  }
  
  /* Optional: Add a semi-transparent overlay to enhance text readability */
  .background-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Adjust opacity and color as needed */
    z-index: -1;
  }
  