@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

/* body, .App {
  font-family: 'Comic Sans MS', 'Comic Sans';
} */

body,
.App {
  font-family: "Source Sans Pro", sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden; /* Prevent scrolling on the entire page */
}

.App {
  position: relative; /* Ensure positioning context */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  color: #ffffff; /* Adjust text color for better contrast */
  /* Optional: Add a background color with transparency */
  background: rgba(0, 0, 0, 0.5);
}

/* Input container styles */
.input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
}

/* Input box styling */
input {
  padding: 10px 15px;
  font-size: 20px;
  overflow-x: scroll;
  background-color: #333333;
  color: #ffffff; /* Input text color */
  border-radius: 25px; /* Fully rounded corners */
  border: none;
  width: 100%;
  height: 40px;
  padding-right: 70px; /* Padding for the button inside */
}

/* Styling the placeholder text */
input::placeholder {
  color: #a6a6a6; /* Placeholder text color (grey) */
  opacity: 1; /* Ensures the color is not transparent */
}

/* Submit button (arrow icon) styling */
/* Submit button (arrow icon) styling */
.submit-button {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  background: #ffffff; /* White background for the button */
  border: none;
  border-radius: 50%; /* Make the button round */
  color: #212121; /* Arrow color */
  font-size: 20px;
  cursor: pointer;
  width: 35px; /* Width of the button to make it circular */
  height: 35px; /* Height of the button to make it circular */
  display: flex;
  align-items: center;
  justify-content: center; /* Center the arrow inside the circle */
  padding: 0;
}

.title {
  color: white;
  height: 8vh;
  /* background-color: #212121; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}

.response-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* background-color: #212121; */
  height: 50vh;
}

.form-container {
  height: 15vh;
  width: 100%;
  /* background-color: #212121; */
}

.response-box {
  color: white;
  width: 60%;
  height: 99%;
  /* background-color: #212121; */
  text-align: left;
  overflow-y: auto;
  margin-bottom: 1%;
}

.response-text {
  font-size: 1.2em;
}

.sample-prompt-container {
  display: flex;
  overflow-x: auto; /* Enables horizontal scrolling */
  overflow-y: hidden; /* Prevents vertical scrolling */
  white-space: nowrap; /* Prevents text from wrapping */
  scroll-behavior: smooth; /* Smooth scrolling */
  width: 60%; /* Adjusted width */
  padding-bottom: 10px;
  position: relative;
  gap: 10px; /* Space between items */
  margin-bottom: 5px;
}

/* Show the scrollbar */
.sample-prompt-container::-webkit-scrollbar {
  height: 8px; /* Make the scrollbar visible */
  background-color: #1e1e1e; /* Background color */
}

/* Scrollbar track */
.sample-prompt-container::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #2c2c2c; /* Track color */
}

/* Scrollbar thumb (draggable part) */
.sample-prompt-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #555; /* Thumb color */
}

/* Scrollbar thumb on hover */
.sample-prompt-container::-webkit-scrollbar-thumb:hover {
  background: #888; /* Darker color when hovered */
}

.sample-prompt {
  flex: 0 0 auto; /* Prevents shrinking */
  min-width: 160px; /* Minimum width for readability */
  max-width: 220px; /* Adjusted max width */
  height: auto; /* Auto height */
  line-height: 1.3; /* Adjust line height */
  max-height: 2.6em; /* Ensures max 2 lines of text */
  overflow: hidden; /* Hides overflow text */
  text-overflow: ellipsis; /* Adds "..." if the text overflows */
  border-radius: 8px;
  padding: 10px;
  background-color: rgba(39, 39, 39, 0.8);
  text-align: center;
  cursor: pointer;
  font-size: 1.1em;
  display: flex;
  align-items: center; /* Center text vertically */
  justify-content: center;
  word-wrap: break-word; /* Ensures words wrap */
  white-space: normal; /* Allows text to break onto multiple lines */
}

.sample-prompt p {
  margin: 0;
  text-align: center;
  font-size: 1em;
}

.sample-prompt:hover {
  background-color: rgba(26, 26, 26, 0.8);
}

.sample-prompt-header-text {
  width: 60%;
  text-align: left;
  margin-bottom: 4px;
  color: #919191;
}

.top-button-container {
  text-align: center;
  margin-top: 3px;
  margin-bottom: 20px;
}

.brawl360-button {
  display: inline-block;
  margin-top: 0px;
  padding: 10px 20px;
  background-color: #2a2a2a; /* Change to your preferred color */
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1.2em;
  box-shadow: 1px 1px 5px #fff;
}

.brawl360-button:hover {
  background-color: #6d00a3;
  box-shadow: 1px 1px 5px #cc65ff;
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
  .title {
    font-size: 2em;
  }
  input {
    width: 80%; /* Set input width to 65% for mobile devices */
    padding-right: 50px;
  }

  .response-box {
    width: 90%; /* Set response box to 90% for mobile devices */
  }
  .response-container {
    height: 45vh;
  }
  .sample-prompt-container {
    width: 100%; /* Adjusted width */
  }

  .sample-prompt {
    font-size: 0.9em;
  }

  .sample-prompt p {
    margin: 0;
    text-align: center;
    font-size: 1em;
  }

  .sample-prompt-header-text {
    width: 98%;
    text-align: left;
    margin-bottom: 4px;
    color: #919191;
  }
}
